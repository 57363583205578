import { createSlice } from "@reduxjs/toolkit";

const initialUpgradeState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  meta: {total : 0},
  links: null,
  entitiesTraffic: null,
  exportData: null,
  currency: null,
  lastError: null,
  upgradeForEdit: undefined,
  upgradeForValid: undefined,
  upgradeForExport: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const upgradeSlice = createSlice({
  name: "upgrade",
  initialState: initialUpgradeState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		upgradeFetched: (state, action) => {
      const { entities, meta, links } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.meta = meta;
      state.links = links;
    },
		exportUpgrade: (state, action) => {
      const { totalCount, entities, currency } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.exportData = entities;
      state.currency = currency;
      state.totalCount = totalCount;
    },
		exportFetchedUpgrade: (state, action) => {
      const { totalCount, export_data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.exportData = export_data;
      state.totalCount = totalCount;
    },
		trafficUpgrade: (state, action) => {
      const { totalCount, entities, currency } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entitiesTraffic = entities;
      state.currency = currency;
      state.totalCount = totalCount;
    },
    upgradeCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.upgrade.push(action.payload.upgrade);
    },
    upgradeDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.upgrade.filter(el => el.upgrade_id !== action.payload.id);
    },
    upgradeDisabled: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.upgrade.filter(el => el.upgrade_id !== action.payload.id);
    },
    getUpgrade: (state, action) => {
      state.actionsLoading = false;
      state.upgradeForEdit = action.payload.upgradeForEdit;
      state.error = null;
    },
    getUpgradeValid: (state, action) => {
      state.actionsLoading = false;
      state.upgradeForValid = action.payload.upgradeForValid;
      state.error = null;
    },
    getExportUpgrade: (state, action) => {
      state.actionsLoading = false;
      if(action.payload.upgradeForExport === undefined) {
        state.upgradeForExport = [];
      }else{
        state.upgradeForExport.push(action.payload.upgradeForExport);
      }
      state.error = null;
    },
    // updateProduct
    upgradeUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.upgrade = state.entities.upgrade.map(entity => {
        if (entity.upgrade_id === action.payload.upgrade.upgrade_id) {
          return action.payload.upgrade;
        }
        return entity;
      });
    },
  },
});
