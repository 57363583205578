import { put, takeLatest } from "redux-saga/effects";

import { getUserByToken } from "./authCrud";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
  Login: "[Login] Action",
  CekToken: "[CekToken] Action",
  ChangePassword: "[ChangePassword] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[RequestUser] Action",
  UserLoaded: "[LoadUser] Auth API",
  CekExpired: "[CekExpired] Action",
  RefreshToken: "[RefreshToken] Action",
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  statusToken: false,
  isExpired: true,
};

export const reducer = persistReducer(
  { storage, key: "v706-demo1-auth", whitelist: ["user", "authToken", "statusToken","isExpired"] },
  (state = initialAuthState, action) => {
    //state.cancelToken cek
    switch (action.type) {
      case actionTypes.Login: {
        const { data } = action.payload;
        return { authToken: data.token, user: data, statusToken:true, isExpired:false };
      }
      
      case actionTypes.CekToken: {
        const { data } = action.payload;
        state.statusToken = data.statusToken === 200 ? true :false;
        return state;
      }
      
      case actionTypes.ChangePassword: {
        const { data } = action.payload;
        return { authToken: data.token, user: data, statusToken:true, isExpired:false };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { statusToken, isExpired } = action.payload;
        state.statusToken = statusToken;
        state.isExpired = isExpired;
        return state;
      }
      
      case actionTypes.CekExpired: {
        const { statusToken, isExpired } = action.payload;
        return {
          ...state,
          statusToken : statusToken === 200 ? true : false,
          isExpired : isExpired,
        };
      }
      
      case actionTypes.RefreshToken: {
        const { data } = action.payload;
        return {
          ...state,
          authToken : data.token
        }
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: data => ({ 
    type: actionTypes.Login, 
    payload: { data } 
  }),
  cekTokenAuth: data => {
    return { 
      type: actionTypes.CekExpired, 
      payload: data 
    }
  },
  refreshTokenAuth: data => ({
    type: actionTypes.RefreshToken, 
    payload: {data}
  }),
  changePassword: data => ({ 
    type: actionTypes.ChangePassword, 
    payload: { data } 
  }),
  register: authToken => ({
    type: actionTypes.Register,
    payload: { authToken }
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: user => ({ 
    type: actionTypes.UserRequested, 
    payload: { user } 
  }),
  fulfillUser: user => ({ 
    type: actionTypes.UserLoaded, 
    payload: { user } 
  })
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });
  
  // yield takeLatest(actionTypes.CekExpired, function* cekTokenAuthSaga() {
  //   yield put(actions.cekTokenAuth());
  // });
  
  yield takeLatest(actionTypes.RefreshToken, function* refreshTokenAuthSaga() {
    yield put(actions.refreshTokenAuth());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data } = yield getUserByToken();
    const insert_data = {
      statusToken : data.status ? true : false
    }
    console.log(insert_data);
    yield put(actions.fulfillUser(insert_data));
  });
}
