import { apiUrl , apiv3Url} from "../../../../_metronic/_helpers";
import axios from "axios";

export const LOGIN_URL = `${apiv3Url}/api/auth/login`;
export const CHANGE_PASSWORD_URL = `${apiv3Url}/api/auth/change-password`;
export const TOKEN_CEK_URL = `${apiv3Url}/api/auth/checkToken`;
export const TOKEN_REFRESH_URL = `${apiv3Url}/api/auth/refreshToken`;
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

// export const ME_URL = `${apiUrl}/user-service/user/me`;
export const ME_URL = `${apiUrl}/user-cqrs-service/user/simple/null`;

export function login(nik, password) {
  return axios.post(LOGIN_URL, { nik, password });
}

export function cekToken(token) {
  return axios.post(TOKEN_CEK_URL,{token: token});
}

export function refreshToken() {
  return axios.post(TOKEN_REFRESH_URL,{message: "refresh"});
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function changePassword(nik, old_password, new_password, confirm_password) {
  return axios.post(
    CHANGE_PASSWORD_URL,
    { 
      nik: nik,
      password: old_password,
      new_password: new_password,
      confirm_password: confirm_password,
    }
  );
}

export function getUserByToken(token) {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
