export default function setupAxios(axios, store, newToken=null) {
  axios.interceptors.request.use(
    config => {
      if(store){
        const {
          auth: { authToken }
        } = store.getState();
        if (authToken) {
          config.headers.Authorization = `Bearer ${authToken}`;
        }
      }

      if(newToken){
        console.log(newToken)
        config.headers.Authorization = `Bearer ${newToken}`;
      }


      return config;
    },
    err => Promise.reject(err)
  );
}
