/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */
import * as authredux from "../../src/app/modules/Auth/_redux/authRedux";
import { cekToken } from "../../src/app/modules/Auth/_redux/authCrud";
import { AuthPage, Logout } from "./modules/Auth";
import { Redirect, Route, Switch } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";

import BasePage from "./BasePage";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import {Layout} from "../_metronic/layout";
import React, { useEffect } from "react";

export function Routes() {

    const {isAuthorized, tokenStatus, isExpired, token} = useSelector(
        ({auth}) => ({
            isAuthorized: auth.authToken ? true : false,
            tokenStatus: auth.statusToken,
            isExpired: auth.isExpired,
            token: auth.authToken,
        }),
        shallowEqual
    );

    const dispatch = useDispatch();
    
    useEffect(() => {
        if(token){
            cekToken(token)
            .then((data_cek) =>{
                const {data , status} = data_cek;
                dispatch(authredux.actions.cekTokenAuth({statusToken : status, isExpired : data.isexpired}));
            })
            .catch((error) => {
                dispatch(authredux.actions.cekTokenAuth({statusToken : error.status, isExpired : true}));
            });
        }
    }, [token,tokenStatus]);

    return (
        <Switch>

            {(!isAuthorized && isExpired) || (isAuthorized && isExpired) ? (
                (
                    <Route>
                        <AuthPage />
                    </Route>
                )
            ) : 
            (<Redirect from="/auth" to="/"/>)}

            <Route path="/error" component={ErrorsPage}/>
            <Route path="/logout" component={Logout}/>

            <Layout>
                <BasePage/>
            </Layout>
        </Switch>
    );
}
